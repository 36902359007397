import React, { useCallback, useEffect, useMemo, useState } from "react";
import cx from "classnames";

import { LocalRoutes } from "constants/routes";
import { StorageKeys } from "constants/storage-keys";
import { foundationProductCodes, learningPathProducts } from "constants/products";
import { Link, useLocation } from "react-router-dom";
import { RegistrationData, RequirementStatus, RequirementType } from "types/registrations";

import "./application-navigation.scss";

export interface ApplicationNavigationProps {
  productType: string;
  productCode: string;
  registrationData: RegistrationData | null;
}

interface NavSteps {
  id: string;
  friendlyName: string;
  route: string;
  requirements: RequirementType[];
  isCurrent?: boolean;
  isComplete?: boolean;
  isLink?: boolean;
}

const courseSteps: Array<NavSteps> = [
  {
    id: "personal",
    friendlyName: "Personal information",
    route: LocalRoutes.APPLICATION,
    requirements: [RequirementType.PersonalInfo, RequirementType.TermsAndConditions],
  },
  {
    id: "checkout",
    friendlyName: "Tuition fee checkout",
    route: LocalRoutes.TUITION_FEE_CHECKOUT,
    requirements: [RequirementType.TuitionFee],
  },
];

const foundationSteps: Array<NavSteps> = [
  {
    id: "personal",
    friendlyName: "Personal information",
    route: LocalRoutes.APPLICATION,
    requirements: [RequirementType.PersonalInfo, RequirementType.TermsAndConditions],
  },
  {
    id: "checkout",
    friendlyName: "Tuition fee checkout",
    route: LocalRoutes.TUITION_FEE_CHECKOUT,
    requirements: [RequirementType.TuitionFee],
  },
];

const certificateSteps: Array<NavSteps> = [
  {
    id: "education",
    friendlyName: "Previous education",
    route: LocalRoutes.APPLICATION_EDUCATION,
    requirements: [RequirementType.EducationInfo],
  },
  {
    id: "personal",
    friendlyName: "Personal information",
    route: LocalRoutes.APPLICATION,
    requirements: [
      RequirementType.PersonalInfo,
      RequirementType.EnglishProficiency,
      RequirementType.TermsAndConditions,
    ],
  },
  {
    id: "identity",
    friendlyName: "Identity documents",
    route: LocalRoutes.APPLICATION_IDENTITY,
    requirements: [RequirementType.PhotoIdentity, RequirementType.GovIdentity],
  },
  {
    id: "checkout",
    friendlyName: "Application fee checkout",
    route: LocalRoutes.APPLICATION_FEE_CHECKOUT,
    requirements: [RequirementType.ApplicationFee],
  },
];

const degreeSteps: Array<NavSteps> = [
  {
    id: "education",
    friendlyName: "Previous education",
    route: LocalRoutes.APPLICATION_EDUCATION,
    requirements: [RequirementType.EducationInfo],
  },
  {
    id: "personal",
    friendlyName: "Personal information",
    route: LocalRoutes.APPLICATION,
    requirements: [
      RequirementType.PersonalInfo,
      RequirementType.EnglishProficiency,
      RequirementType.TermsAndConditions,
    ],
  },
  {
    id: "identity",
    friendlyName: "Identity documents",
    route: LocalRoutes.APPLICATION_IDENTITY,
    requirements: [RequirementType.PhotoIdentity, RequirementType.GovIdentity],
  },
  {
    id: "checkout",
    friendlyName: "Application fee checkout",
    route: LocalRoutes.APPLICATION_FEE_CHECKOUT,
    requirements: [RequirementType.ApplicationFee],
  },
];

const mbaSteps: Array<NavSteps> = [
  {
    id: "education",
    friendlyName: "Previous education",
    route: LocalRoutes.APPLICATION_EDUCATION,
    requirements: [RequirementType.EducationInfo],
  },
  {
    id: "learning_path",
    friendlyName: "Degree learning path",
    route: LocalRoutes.APPLICATION_DEGREE_LEARNING_PATH,
    requirements: [RequirementType.EducationInfo],
  },
  {
    id: "personal",
    friendlyName: "Personal information",
    route: LocalRoutes.APPLICATION,
    requirements: [
      RequirementType.PersonalInfo,
      RequirementType.EnglishProficiency,
      RequirementType.TermsAndConditions,
    ],
  },
  {
    id: "identity",
    friendlyName: "Identity documents",
    route: LocalRoutes.APPLICATION_IDENTITY,
    requirements: [RequirementType.PhotoIdentity, RequirementType.GovIdentity],
  },
  {
    id: "checkout",
    friendlyName: "Application fee checkout",
    route: LocalRoutes.APPLICATION_FEE_CHECKOUT,
    requirements: [RequirementType.ApplicationFee],
  },
];

const ApplicationNavigation = (props: ApplicationNavigationProps) => {
  const { productType, productCode, registrationData } = props;
  const location = useLocation();

  const [navSteps, setNavSteps] = useState<Array<NavSteps>>([]);

  const mapNavSteps = useCallback(() => {
    if (
      location.pathname === LocalRoutes.APPLICATION_SUCCESS ||
      location.pathname === LocalRoutes.APPLICATION_PENDING
    ) {
      setNavSteps([]);
      return;
    }

    const admissionDecisionReq = registrationData?.requirements.find(
      (item) => item.requirement === RequirementType.AdmissionDecision,
    );
    if (admissionDecisionReq && admissionDecisionReq.status === RequirementStatus.Fulfilled) {
      setNavSteps([]);
      return;
    }

    let navStepsArray: Array<NavSteps> = [...navSteps];

    switch (productType) {
      case "Course":
        navStepsArray = courseSteps;
        break;
      case "Certificate":
        if (foundationProductCodes.includes(productCode)) navStepsArray = foundationSteps;
        else navStepsArray = certificateSteps;
        break;
      case "Degree":
        if (learningPathProducts.includes(productCode)) navStepsArray = mbaSteps;
        else navStepsArray = degreeSteps;
        break;
      default:
        navStepsArray = [];
        break;
    }

    if (navStepsArray?.length) {
      navStepsArray.forEach((step, n) => {
        navStepsArray[n].isCurrent = step.route === location.pathname;
        let isComplete = true;
        step.requirements.forEach((stepRequirement) => {
          const matchedRequirement = registrationData?.requirements.find((r) => r.requirement === stepRequirement);
          if (isComplete && matchedRequirement?.status === RequirementStatus.Pending) isComplete = false;
        });
        navStepsArray[n].isComplete = isComplete;
        navStepsArray[n].isLink = n === 0 || isComplete || navStepsArray[n - 1].isComplete;
      });
    }

    setNavSteps([...navStepsArray]);
  }, [location.pathname, productType, registrationData?.requirements]);

  useEffect(() => {
    mapNavSteps();
  }, [mapNavSteps]);

  // Memoise the list of navigation steps
  const generateStepButton = useMemo(() => {
    // No steps to display
    if (!navSteps?.length) return null;

    return (
      <section data-testid="application-nav-stepper" className="application-nav-stepper">
        {navSteps.map((step, n) => (
          <div key={step.id}>
            {step.isLink && (
              <Link
                onClick={() =>
                  sessionStorage.setItem(
                    StorageKeys.CURRENT_ROUTE,
                    `${step.route}?type=${productType}&product=${productCode}`,
                  )
                }
                to={`${step.route}?type=${productType}&product=${productCode}`}
                aria-label={`Go to ${step.friendlyName} page`}
                className={cx(
                  "application-nav-stepper__step",
                  step.isComplete && "application-nav-stepper__step--complete",
                  step.isCurrent && "application-nav-stepper__step--current",
                )}
              >
                {step.isComplete && !step.isCurrent ? <>&#10004;</> : <>{n + 1}</>}
              </Link>
            )}
            {!step.isLink && (
              <span
                aria-label={`Previous steps must be completed before ${step.friendlyName} can be reached`}
                className="application-nav-stepper__step application-nav-stepper__step--disabled"
              >
                {n + 1}
              </span>
            )}
          </div>
        ))}
      </section>
    );
  }, [navSteps, productCode, productType]);

  return <>{generateStepButton}</>;
};

export default ApplicationNavigation;
