import React from "react";

import { IonButton } from "@ionic/react";

import { NxuAlert } from "@nexford/nexford-ui-component-library";
import GetInTouch from "components/atom/get-in-touch";

export interface CatalogueRedirectMessageProps {
  errMsg?: string;
  redirectToCatalogue?: boolean;
  resetEmail?: () => void;
}

// Content block for applicant to be directed back to the catalogue
const ApplicationErrorBlock = (props: CatalogueRedirectMessageProps) => (
  <>
    <NxuAlert message={props.errMsg || `Uh-oh! We're having trouble proceeding with your application.`} />
    <GetInTouch>
      {props.redirectToCatalogue && (
        <>
          <p>
            <a href={process.env.REACT_APP_APPLYV1_URL}>Head over to our catalog to find your course.</a>
          </p>
          {!!props.resetEmail && (
            <>
              <p>Or</p>
              <p>
                <IonButton onClick={props.resetEmail} fill="outline">
                  Try with another email
                </IonButton>
              </p>
            </>
          )}
          <p>Any questions? Get in touch and we’ll help.</p>
        </>
      )}
    </GetInTouch>
  </>
);

export default ApplicationErrorBlock;
