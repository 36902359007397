import React, { useEffect, useState } from "react";

import { IonButton, IonButtons, IonModal, IonHeader, IonToolbar, IonTitle, IonContent, IonFooter } from "@ionic/react";

import { PAYPAL_URL } from "constants/external-routes";
import { PPO } from "types/payments";

import { NxuPrimaryButton } from "@nexford/nexford-ui-component-library";
import GetInTouch from "components/atom/get-in-touch";

import "./external-provider-modal.scss";
import { BankTransferContent, PaypalContent } from "./modal-content";

const { PAYPAL, TRANSFER } = PPO;

export interface ProviderModalProps {
  type: string;
  isOpen: boolean;
  closeModal: () => void;
  learnerId: string;
}

/**
 * Display an info modal for payment methods that require leaving the app
 */
const ExternalProviderModal = (props: ProviderModalProps) => {
  const { isOpen, closeModal, type, learnerId } = props;

  const [modalTitle, setModalTitle] = useState<string>();
  const [paymentComplete, setPaymentComplete] = useState(false);

  const isPaypalPay = type === PAYPAL;
  const isTransferPay = type === TRANSFER;

  useEffect(() => {
    if (type === PAYPAL) setModalTitle("Pay with PayPal");
    if (type === TRANSFER) setModalTitle("Pay with Bank Transfer");
  }, [setModalTitle, type]);

  const handleClose = () => {
    closeModal();
    setPaymentComplete(false);
  };

  return (
    <IonModal
      data-testid="provider-modal"
      isOpen={isOpen}
      onDidDismiss={handleClose}
      className="nxu-modal provider-modal"
    >
      <IonHeader className="provider-modal__header">
        <IonToolbar>
          <IonTitle data-testid="provider-modal-title">{modalTitle}</IonTitle>
          <IonButtons slot="end">
            <IonButton fill="outline" onClick={handleClose}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="provider-modal__content">
        <div className="provider-modal__content-inner">
          {isPaypalPay && <PaypalContent paymentComplete={paymentComplete} learnerId={learnerId} />}
          {isTransferPay && <BankTransferContent learnerId={learnerId} />}
        </div>
        <div className="provider-modal__content-inner">
          <GetInTouch type="billings" />
        </div>
      </IonContent>

      <IonFooter className="provider-modal__footer">
        <IonToolbar>
          {!paymentComplete && isPaypalPay && (
            <NxuPrimaryButton onClick={() => setPaymentComplete(true)} href={PAYPAL_URL} target="_blank">
              Pay with PayPal
            </NxuPrimaryButton>
          )}
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};

export default ExternalProviderModal;
