import React from "react";
import ReactMarkdown from "react-markdown";

import { IonButton, IonButtons, IonModal, IonHeader, IonToolbar, IonTitle, IonContent } from "@ionic/react";

import { NxuComponentLoading } from "@nexford/nexford-ui-component-library";

import "./contentful-modal.scss";

export interface ContentfulModalProps {
  content: string;
  modalTitle: string;
  isOpen: boolean;
  isLoading: boolean;
  isError: boolean;
  closeModal: () => void;
}

const ContentfulModal = (props: ContentfulModalProps) => {
  const { content, modalTitle, isOpen, isLoading, isError, closeModal } = props;

  const handleClose = () => {
    closeModal();
  };

  const Content = () => {
    if (isLoading) {
      return <NxuComponentLoading />;
    }
    if (isError) {
      return <div className="contenful-modal__content-inner">There was an error on loading the {modalTitle}</div>;
    }

    return <div className="contenful-modal__content-inner">{content && <ReactMarkdown>{content}</ReactMarkdown>}</div>;
  };

  return (
    <IonModal
      data-testid="contenful-modal"
      isOpen={isOpen}
      onDidDismiss={handleClose}
      className="nxu-modal contenful-modal"
    >
      <IonHeader className="contenful-modal__header">
        <IonToolbar>
          <IonTitle data-testid="contenful-modal-title">{modalTitle}</IonTitle>
          <IonButtons slot="end">
            <IonButton fill="outline" onClick={handleClose}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="contenful-modal__content">{Content()}</IonContent>
    </IonModal>
  );
};

export default ContentfulModal;
