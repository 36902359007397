import { PaymentMethodCreateParams } from "@stripe/stripe-js";
import {
  CardholderDetails,
  CurrencyProvider,
  PaymentPollingSubStatusOptions,
  PaymentsTypeOptions,
  PaymentTypes,
  PPO,
  ProviderItem,
  ProviderTypes,
  StartPaymentRegistrationPayload,
  StartPaymentStripeDetails,
} from "types/payments";
import { Product } from "types/admissions";
import { SelectItem } from "types/forms";
import { IonIcon } from "@ionic/react";
import { businessOutline, cardOutline, logoPaypal } from "ionicons/icons";

import { ReactComponent as FlywireLogo } from "assets/img/third-party/flywire.svg";
import { ReactComponent as FlutterwaveLogo } from "assets/img/third-party/flutterwave.svg";

const { FLYWIRE, FLUTTERWAVE, STRIPE, TRANSFER, PAYPAL } = PPO;

export function paymentRegistrationPayloadGenerator(
  paymentType: PaymentTypes,
  provider: ProviderTypes,
  programDetails: Product,
  callbackId: string,
  country: string,
  amount: number,
  startMonth: number,
  couponCode: string | null,
  startPaymentStripeDetails?: StartPaymentStripeDetails,
): StartPaymentRegistrationPayload {
  return {
    paymentReference: callbackId,
    paymentType,
    provider,
    productType: programDetails.ProductType,
    productCode: programDetails.ProductCode,
    productLevel: programDetails.ProductLevel,
    countryCode: country,
    paymentFrequency: 1,
    startMonth,
    amount,
    couponCode,
    startPaymentStripeDetails,
  };
}

export function generateProvidersList(currencies: CurrencyProvider[]) {
  let defaultList: Array<ProviderItem> = [];
  if (currencies) {
    currencies.forEach((c) => {
      defaultList.push({
        provider: c.provider,
        currencyCode: c.currencyCode,
        providerAmount: c.amountUSD || c.amount,
      });
    });
    defaultList = [...defaultList];
  }
  return defaultList;
}

// Generate provider icons for the payment option buttons
export function getProviderIcon(provider: ProviderTypes) {
  switch (provider.toLowerCase()) {
    case STRIPE: {
      return <IonIcon icon={cardOutline} />;
    }
    case FLYWIRE: {
      return <FlywireLogo />;
    }
    case FLUTTERWAVE: {
      return <FlutterwaveLogo />;
    }
    case PAYPAL: {
      return <IonIcon icon={logoPaypal} />;
    }
    case TRANSFER: {
      return <IonIcon icon={businessOutline} />;
    }
    default: {
      return <IonIcon icon={cardOutline} />;
    }
  }
}

// Generate label text for the flutterwave payment option
function generateFlutterwaveLabel(currencyCode?: string): string {
  switch (currencyCode) {
    case "EGP":
      return `Pay in ${currencyCode} - Google Pay / Card`;
    case "ETB":
      return `Pay in ${currencyCode} - Card`;
    case "GHS":
      return `Pay in ${currencyCode} - Mobile Money / Card`;
    case "KES":
      return `Pay in ${currencyCode} - M-Pesa / Card`;
    case "NGN":
      return `Pay in ${currencyCode} - Bank Transfer / Card`;
    case "RWF":
      return `Pay in ${currencyCode} - Mobile Money / Card`;
    case "TZS":
      return `Pay in ${currencyCode} - Mobile Money / Card`;
    case "UGX":
      return `Pay in ${currencyCode} - Mobile Money / Card`;
    case "ZAR":
      return `Pay in ${currencyCode} - Mobile Money / Card`;
    default:
      return `Pay in ${currencyCode} - Flutterwave`;
  }
}

// Generate label text for the payment option buttons
export function generateProviderSelectionButtonLabel(provider: ProviderTypes, currencyCode?: string): string {
  let readableProvider: string = provider;

  if (provider === PPO.TRANSFER) readableProvider = "Bank Transfer";
  if (provider === PPO.PAYPAL) readableProvider = "PayPal";
  if (provider === PPO.STRIPE) readableProvider = "Pay in USD - Debit/Credit Card";
  if (provider.toLowerCase() === PPO.FLYWIRE) readableProvider = `Pay in Local Currency - Flywire`;
  if (provider.toLowerCase() === PPO.FLUTTERWAVE) readableProvider = generateFlutterwaveLabel(currencyCode);

  return readableProvider;
}

// Populate the select menu for the tuition start date
export function generateProgramStartDateList(programStartDates: Array<string>): Array<SelectItem> {
  const dateList: Array<SelectItem> = [];

  programStartDates.forEach((item) => {
    const newItem: SelectItem = {
      label: new Date(item).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      }),
      value: item.slice(0, 10),
    };
    dateList.push(newItem);
  });

  return dateList;
}

// Convert card form values to a format that can be used by the Stripe API
export function mapStripePaymentData(cardholderDetails: CardholderDetails): PaymentMethodCreateParams.BillingDetails {
  return {
    name: `${cardholderDetails.FirstName} ${cardholderDetails.LastName}`,
    address: {
      line1: cardholderDetails.Street,
      line2: "",
      city: cardholderDetails.City,
      state: "",
      postal_code: cardholderDetails.PostalCode,
    },
    email: cardholderDetails.Email,
  };
}

// Convert Stripe Payment resuts
export enum StripeResponeErrorCode {
  applicationStageIsInvalid = "ApplicationStageIsInvalid",
  feeAlreadyExists = "FeeAlreadyExists",
  invalidCouponCode = "InvalidCouponCode",
  invalidPaymentDetails = "InvalidPaymentDetails",
  parameterIsRequired = "ParameterIsRequired",
  parameterIsInvalid = "ParameterIsInvalid",
  expectedAmountMismatch = "ExpectedAmountMismatch",
}

export function mapStripeErrorCode(errorCode: string): {
  error: string;
  disableRetry: boolean;
  errorCode: string;
} {
  switch (errorCode) {
    case StripeResponeErrorCode.applicationStageIsInvalid: {
      return {
        error: `System busy. Please try again. Error: ${errorCode}`,
        disableRetry: false,
        errorCode,
      };
    }
    case StripeResponeErrorCode.feeAlreadyExists: {
      return {
        error: `Uh-oh! There was an error on charging this fee. Please contact support to confirm the status of the payment. Error: ${errorCode}`,
        disableRetry: true,
        errorCode,
      };
    }
    case StripeResponeErrorCode.expectedAmountMismatch: {
      return {
        error: `Payment amount has been recalculated, please refresh and try again Error: ${errorCode}`,
        disableRetry: false,
        errorCode,
      };
    }
    default: {
      return {
        error: `Uh-oh! There was an error on charging this fee. Please contact support to confirm the status of the payment. Error: ${errorCode}`,
        disableRetry: true,
        errorCode,
      };
    }
  }
}

export function getPaymentErrorMessage(errorCode?: string): string {
  switch (errorCode) {
    case PaymentPollingSubStatusOptions.GENERIC_DECLINE:
    case PaymentPollingSubStatusOptions.DECLINED_TRANSACTION:
    case PaymentPollingSubStatusOptions.STOLEN_CARD:
    case PaymentPollingSubStatusOptions.SECURE_DECLINED:
    case PaymentPollingSubStatusOptions.VELOCITY_EXCEEDED:
    case PaymentPollingSubStatusOptions.FRAUDULENT_CARD:
      return "Transaction declined. Use a different card or contact your card issuer.";
    case PaymentPollingSubStatusOptions.INSUFFICIENT_FUNDS:
      return "Your card cannot be charged due to insufficient funds. Please try another card or fund your card and try again.";
    case PaymentPollingSubStatusOptions.EXPIRED_CARD:
      return "Transaction declined due to expired card. Check the expiration date or use a different card.";
    case PaymentPollingSubStatusOptions.INCORRECT_CVC:
      return "Invalid transaction due to incorrect card details. Try again.";
    case PaymentPollingSubStatusOptions.PROCESSING_ERROR:
      return "An error occurred while processing the card. Try again or use a different card.";
    case PaymentPollingSubStatusOptions.FAILED_AUTHENTICATION:
      return "Transaction declined due to authentication failure. Try again or use a different payment method.";
    case PaymentPollingSubStatusOptions.INCORRECT_PIN:
      return "Transaction declined due to incorrect PIN. Try again or use a different card.";
    case PaymentPollingSubStatusOptions.PAYMENT_REFUSED:
      return "Transaction failed. Reach out to your bank to get more information, or try a different payment method.";
    case PaymentPollingSubStatusOptions.PAYMENT_CANCELLED:
    case PaymentPollingSubStatusOptions.UNKNOWN_ERROR:
    default:
      return "Transaction failed. Try again or use a different payment method.";
  }
}
