import { NxuAlert, NxuContentLoading } from "@nexford/nexford-ui-component-library";
import CardPanel from "components/atom/card-panel";
import GetInTouch from "components/atom/get-in-touch";
import ApplicantStatusBlock from "components/molecule/applicant-status-block/applicant-status-block";
import ApplicationErrorBlock from "components/molecule/application-error-block";
import ApplicationTitlePanel from "components/molecule/application-title-panel";
import Catalog from "components/molecule/catalog";
import EmptyVerificationForm from "components/molecule/empty-verification-form";
import { PageContent } from "components/molecule/page-wrap/page-wrap";
import ReturningApplicant from "components/molecule/returning-applicant";
import { NEXFORD_HOME } from "constants/external-routes";
import { LocalRoutes } from "constants/routes";
import { learningPathProducts } from "constants/products";
import { StorageKeys } from "constants/storage-keys";
import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ApplicationStatusOptions, Product } from "types/admissions";
import { FetchLearnerProfileResponse, SubmitLearnerProfileResponse } from "types/learner-profile";
import { ProductType } from "types/product";
import {
  AdmissionApplicationDataResponse,
  RegistrationData,
  RegistrationRequirement,
  RegistrationResponse,
  RegistrationStatus,
  RequirementStatus,
  RequirementType,
} from "types/registrations";
import { useProgramDetails } from "utils/hooks/admissions";
import { useLearnerProfileInfo } from "utils/hooks/learner-profile";
import { useRegistration, useStartRegistration } from "utils/hooks/registrations";
import ApplicationNavigation from "components/molecule/application-navigation";
import { isTestEnv } from "../../constants/feature-flags";

export interface RegistrationContextType {
  loading: boolean;
  registrationData: RegistrationData | null;
  admissionRecord: AdmissionApplicationDataResponse | null;
  authorised: boolean;
  token: string | null;
  email: string | null;
  learnerId: string | null;
  programDetails?: Product;
  learnerProfileData?: FetchLearnerProfileResponse;
  CompleteRequirements: (
    submitRequirement: () => Promise<any>,
    requirements: RequirementType[],
    skipNavigation?: boolean,
    retainProcessingFlag?: boolean,
  ) => void;
  SetLearnerProfileData: (data: SubmitLearnerProfileResponse) => void;
  SwitchApplication: (newType: string, newCode: string) => void;
  RedirectToApplyUI: (onMatch: (data?: RegistrationResponse) => boolean) => void;
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface RegistrationProviderProps {
  children?: React.ReactNode;
}

const sortedRequirements: RequirementType[] = [
  RequirementType.EducationInfo,
  RequirementType.PreviousDegreeLevel,
  RequirementType.PersonalInfo,
  RequirementType.EnglishProficiency,
  RequirementType.TermsAndConditions,
  RequirementType.PhotoIdentity,
  RequirementType.GovIdentity,
  RequirementType.ApplicationFee,
  RequirementType.AdmissionDecision,
  RequirementType.Agreement,
  RequirementType.TuitionFee,
];

const routeMapping: { [requirement in RequirementType]: string | null } = {
  EducationInfo: LocalRoutes.APPLICATION_EDUCATION,
  PersonalInfo: LocalRoutes.APPLICATION,
  EnglishProficiency: LocalRoutes.APPLICATION,
  TermsAndConditions: LocalRoutes.APPLICATION,
  PhotoIdentity: LocalRoutes.APPLICATION_IDENTITY,
  GovIdentity: LocalRoutes.APPLICATION_IDENTITY,
  PreviousDegreeLevel: LocalRoutes.APPLICATION_EDUCATION,
  TuitionFee: LocalRoutes.TUITION_FEE_CHECKOUT,
  ApplicationFee: LocalRoutes.APPLICATION_FEE_CHECKOUT,
  AdmissionDecision: LocalRoutes.APPLICATION_PENDING,
  Agreement: isTestEnv ? LocalRoutes.TUITION_FEE_CHECKOUT : null,
};

interface ApplyNxuSupport {
  ValidAdmissionContinueStates: string[];
  SupportedInApplyNxu: boolean;
}

// fullDegreeStatus should only be available on test environments
const limitedApprovedProductStatus = {
  ValidAdmissionContinueStates: [ApplicationStatusOptions.APPLYING],
  SupportedInApplyNxu: true,
};

const fullApprovedProductStatus = {
  ValidAdmissionContinueStates: [ApplicationStatusOptions.APPLYING, ApplicationStatusOptions.ENROLLING],
  SupportedInApplyNxu: true,
};

const supportedProductAdmissionStates: { [product: string]: ApplyNxuSupport } = {
  // Types
  COURSE: {
    ValidAdmissionContinueStates: [ApplicationStatusOptions.APPLYING, ApplicationStatusOptions.ENROLLING],
    SupportedInApplyNxu: true,
  },
  BOOTCAMP: {
    ValidAdmissionContinueStates: [ApplicationStatusOptions.APPLYING, ApplicationStatusOptions.ENROLLING],
    SupportedInApplyNxu: true,
  },
  CERTIFICATE: isTestEnv ? fullApprovedProductStatus : limitedApprovedProductStatus,
  DEGREE: isTestEnv ? fullApprovedProductStatus : limitedApprovedProductStatus,
  // Codes
  FND5000: {
    ValidAdmissionContinueStates: [ApplicationStatusOptions.APPLYING, ApplicationStatusOptions.ENROLLING],
    SupportedInApplyNxu: true,
  },
  FBA5000: {
    ValidAdmissionContinueStates: [ApplicationStatusOptions.APPLYING, ApplicationStatusOptions.ENROLLING],
    SupportedInApplyNxu: true,
  },
  FDT5000: {
    ValidAdmissionContinueStates: [ApplicationStatusOptions.APPLYING, ApplicationStatusOptions.ENROLLING],
    SupportedInApplyNxu: true,
  },
};

const routesWithEmailVerification: string[] = [LocalRoutes.HOME, LocalRoutes.APPLICATION_COMPLETE];
const routesWithoutCatalog: string[] = [LocalRoutes.APPLICATION_SUCCESS, LocalRoutes.APPLICATION_PENDING];

const RegistrationContext = createContext<RegistrationContextType>({
  loading: false,
  authorised: false,
  token: null,
  email: null,
  learnerId: null,
  registrationData: null,
  admissionRecord: null,
  programDetails: undefined,
  learnerProfileData: undefined,
  CompleteRequirements: () => undefined,
  SetLearnerProfileData: () => undefined,
  SwitchApplication: () => undefined,
  RedirectToApplyUI: (_) => false,
  setProcessing: () => undefined,
});

export function RegistrationProvider({ children }: RegistrationProviderProps) {
  const [registrationData, setRegistrationData] = useState<RegistrationData | null>(null);
  const [admissionRecord, setAdmissionRecord] = useState<AdmissionApplicationDataResponse | null>(null);
  const [validAdmissionRecord, setValidAdmissionRecord] = useState<boolean>(false);

  const [token, setToken] = useState(sessionStorage.getItem(StorageKeys.APPLICATION_TOKEN));
  const [email, setEmail] = useState(sessionStorage.getItem(StorageKeys.APPLICATION_EMAIL));
  const [learnerId, setLearnerId] = useState(sessionStorage.getItem(StorageKeys.LEARNER_ID));
  const [learnerProfileData, setLearnerProfileData] = useState<FetchLearnerProfileResponse>();

  const [redirectMatch, setRedirectMatch] = useState(() => (_?: RegistrationResponse) => true);
  const [processing, setProcessing] = useState(false);

  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const showEmailBlock = routesWithEmailVerification.includes(location.pathname);

  const productTypeSearchParam = searchParams.get("type");
  const productTypeSearchParamCapitalise = (productTypeSearchParam &&
    productTypeSearchParam[0].toUpperCase() + productTypeSearchParam.slice(1)) as ProductType;

  const productCodeSearchParam = searchParams.get("product");
  const productCodeSearchParamCapitalise = productCodeSearchParam && productCodeSearchParam.toUpperCase();

  const hasProductParams = productTypeSearchParamCapitalise && productCodeSearchParamCapitalise;

  const productType = productTypeSearchParamCapitalise || registrationData?.productType || admissionRecord?.productType;
  const productCode =
    productCodeSearchParamCapitalise || registrationData?.productCode || admissionRecord?.productCode || "";

  function supportsRegistration(type: string, code: string) {
    // Type supported
    if (supportedProductAdmissionStates[type.toUpperCase()]?.SupportedInApplyNxu) {
      return true;
    }
    // Code supported
    if (supportedProductAdmissionStates[code.toUpperCase()]?.SupportedInApplyNxu) {
      return true;
    }
    return false;
  }

  function supportsAdmissionContinuation(type: string, code: string, status: string) {
    // Admission state exists in type list
    if (supportedProductAdmissionStates[type.toUpperCase()]?.ValidAdmissionContinueStates.find((x) => x === status)) {
      return true;
    }
    // Admission state exists in code list
    if (supportedProductAdmissionStates[code.toUpperCase()]?.ValidAdmissionContinueStates.find((x) => x === status)) {
      return true;
    }
    return false;
  }

  const isSupportedProduct = !hasProductParams || supportsRegistration(productType, productCode);

  const {
    data: registrationApiData,
    error: registrationApiDataError,
    isSuccess: registrationDataSuccess,
    isFetching: registrationDataFetching,
  } = useRegistration(token || "", productType, productCode, redirectMatch);

  const {
    data: learnerProfileApiData,
    isSuccess: learnerProfileDataSuccess,
    isFetching: learnerProfileDataFetching,
  } = useLearnerProfileInfo(token || "");

  const {
    data: programDetails,
    isLoading: programDetailsLoading,
    error: programDetailsError,
    isSuccess: programDetailsSuccess,
  } = useProgramDetails(productType, productCode);

  function SetLearnerProfileData(data: SubmitLearnerProfileResponse) {
    setLearnerProfileData({
      Email: data.email,
      FirstName: data.firstName,
      LastName: data.lastName,
      BirthYear: data.birthYear,
      PhoneCountryCode: data.phoneCountryCode,
      PhoneNumber: data.phoneNumber,
      City: data.city,
      State: data.state,
      Nationality: data.nationality,
      Country: data.country,
      Gender: data.gender,
      HowFoundNexford: data.howFoundNexford,
    });
  }

  function SetAuthenticationInfo(newToken: string, newEmail: string) {
    setToken(newToken);
    setEmail(newEmail);
  }

  function SortRequirements(requirements: RegistrationRequirement[]) {
    requirements.sort((a, b) => {
      if (sortedRequirements.indexOf(a.requirement) > sortedRequirements.indexOf(b.requirement)) {
        return 1;
      }

      return -1;
    });
  }

  function Reset() {
    setLearnerId(null);
    setEmail(null);
    setToken(null);
    setRegistrationData(null);
    setAdmissionRecord(null);
    sessionStorage.removeItem(StorageKeys.APPLICATION_EMAIL);
    sessionStorage.removeItem(StorageKeys.APPLICATION_TOKEN);
    sessionStorage.removeItem(StorageKeys.LEARNER_ID);
    sessionStorage.removeItem(StorageKeys.CURRENT_ROUTE);
  }

  function CreateAppAppRoute() {
    if (!isSupportedProduct && hasProductParams)
      return `${process.env.REACT_APP_APPLYV1_URL}/account/login?type=${productType}&product=${productCode}`;

    if (token) return `${process.env.REACT_APP_APPLYV1_URL}/account/verify?key=${token}`;

    return `${process.env.REACT_APP_APPLYV1_URL}`;
  }

  function RedirectToApplyUI(onMatch?: (data?: RegistrationResponse) => boolean) {
    setProcessing(true);
    if (onMatch) {
      setRedirectMatch(() => (data?: RegistrationResponse) => {
        const isMatch = onMatch(data);
        if (isMatch) {
          window.location.href = CreateAppAppRoute();
        }
        return isMatch;
      });
    } else {
      window.location.href = CreateAppAppRoute();
    }
  }

  function NavigateToNextPage(type: string, product: string, requirements: RegistrationRequirement[]) {
    if (requirements.every((r) => r.status === RequirementStatus.Fulfilled)) {
      navigate(LocalRoutes.APPLICATION_SUCCESS);
    } else {
      const nextRequirement = requirements.find((r) => r.status == RequirementStatus.Pending);
      if (nextRequirement) {
        const nextPage = routeMapping[nextRequirement.requirement];
        if (nextPage) {
          if (learningPathProducts.includes(product)) {
            if (
              location.pathname === LocalRoutes.APPLICATION_EDUCATION ||
              location.pathname === LocalRoutes.APPLICATION_DEGREE_LEARNING_PATH
            ) {
              // Applicant is already on screening pages, ensure the URL has the product params, but allow the page to handle any further redirect
              navigate({
                pathname: location.pathname,
                search: `type=${type}&product=${product}`,
              });
            } else {
              navigate({
                pathname: nextPage,
                search: `type=${type}&product=${product}`,
              });
            }
          } else {
            navigate({
              pathname: nextPage,
              search: `type=${type}&product=${product}`,
            });
          }
        }
        // We have no route, got to AppApp
        else {
          RedirectToApplyUI();
        }
      }
    }
  }

  const { isPending: startRegistrationPending, mutate: StartRegistration } = useStartRegistration((resp) => {
    SortRequirements(resp.requirements);
    setRegistrationData(resp);
    const existingSessionRoute = sessionStorage.getItem(StorageKeys.CURRENT_ROUTE);
    if (existingSessionRoute && existingSessionRoute.includes(resp.productCode)) {
      // There's an existing session and the product hasn't changed
      navigate(existingSessionRoute);
    } else {
      NavigateToNextPage(resp.productType, resp.productCode, resp.requirements);
    }
  });

  function CompleteRequirements(
    submitRequirement: () => Promise<any>,
    requirements: RequirementType[],
    skipNavigation?: boolean,
    retainProcessingFlag?: boolean,
  ) {
    setProcessing(true);
    sessionStorage.removeItem(StorageKeys.CURRENT_ROUTE);
    submitRequirement()
      .then(() => {
        if (registrationData) {
          const updatedRequirements = registrationData.requirements?.map((r) =>
            requirements.findIndex((rr) => rr == r.requirement) > -1
              ? { ...r, status: RequirementStatus.Fulfilled }
              : r,
          );

          setRegistrationData({
            ...registrationData,
            requirements: updatedRequirements,
          });
          if (!skipNavigation) {
            NavigateToNextPage(registrationData.productType, registrationData.productCode, updatedRequirements);
          }
        }
      })
      .finally(() => {
        if (!retainProcessingFlag) setProcessing(false);
      });
  }

  function SwitchApplication(newType: string, newCode: string) {
    sessionStorage.removeItem(StorageKeys.CURRENT_ROUTE);
    StartRegistration({ productType: newType, productCode: newCode });
  }

  // Identify next step after get Registration request is complete
  useEffect(() => {
    if (registrationDataSuccess && !programDetailsLoading) {
      const { registrationDto, applicationDto } = registrationApiData;

      if (registrationDto?.status === RegistrationStatus.Completed) {
        SortRequirements(registrationDto.requirements);
        setRegistrationData(registrationDto);

        if (
          location.pathname !== LocalRoutes.APPLICATION_SUCCESS &&
          location.pathname !== LocalRoutes.APPLICATION_COMPLETE
        ) {
          navigate(LocalRoutes.APPLICATION_COMPLETE);
        }
        return;
      }

      const stateIsStale =
        !registrationData || registrationData?.productCode !== registrationApiData.registrationDto?.productCode;
      const validProduct = programDetails && isSupportedProduct;
      const admissionOk = !applicationDto || validAdmissionRecord;
      if (stateIsStale && validProduct && admissionOk) {
        StartRegistration({
          productType: registrationDto?.productType || programDetails?.ProductType || "",
          productCode: registrationDto?.productCode || programDetails?.ProductCode || "",
        });
      }
    }
  }, [
    // Note: Don't allow the dependency array to reference "navigate" or "NavigateToNextPage", it will trigger a memory leak on the useEffect
    StartRegistration,
    isSupportedProduct,
    programDetails,
    validAdmissionRecord,
    programDetailsLoading,
    programDetailsSuccess,
    registrationApiData,
    registrationDataFetching,
    registrationDataSuccess,
  ]);

  // When admission data successfully loads, set the data to the context state
  useEffect(() => {
    if (registrationDataSuccess) {
      const { registrationDto, applicationDto } = registrationApiData;
      if (applicationDto) {
        setAdmissionRecord(applicationDto);

        if (registrationDto) {
          if (registrationDto.requirements.find((r) => r.status === RequirementStatus.Fulfilled)) {
            // registrationDto already includes Fulfilled requirements, can assume this is a valid admission regardless of applicationDtos
            setValidAdmissionRecord(true);
            return;
          }
        }

        setValidAdmissionRecord(
          supportsAdmissionContinuation(applicationDto.productType, applicationDto.productCode, applicationDto.status),
        );
      }
    }
  }, [registrationApiData, registrationDataSuccess]);

  // When learner profile successfully loads, set the data to the context state
  useEffect(() => {
    if (learnerProfileDataSuccess) setLearnerProfileData(learnerProfileApiData);
  }, [learnerProfileApiData, learnerProfileDataSuccess]);

  const loading =
    registrationDataFetching ||
    startRegistrationPending ||
    processing ||
    programDetailsLoading ||
    learnerProfileDataFetching;

  const hasActiveRegistration = !!registrationData?.status;
  const hasActiveAdmission = !!admissionRecord?.status;

  const contextValues = {
    loading,
    authorised: !!token,
    token,
    email,
    learnerId,
    registrationData,
    admissionRecord,
    programDetails,
    learnerProfileData,
    CompleteRequirements,
    SetLearnerProfileData,
    SwitchApplication,
    RedirectToApplyUI,
    setProcessing,
  };

  if (
    !isSupportedProduct &&
    !programDetailsLoading &&
    !(programDetailsError || (programDetailsSuccess && !programDetails))
  ) {
    RedirectToApplyUI();
  }

  if (registrationApiDataError) {
    // ToDo: Improve the UI here
    return (
      <PageContent className="application-page">
        <ApplicationErrorBlock />
      </PageContent>
    );
  }

  if (loading) {
    return (
      <RegistrationContext.Provider value={contextValues}>
        <PageContent className="application-page">
          <h1 />
          {hasProductParams && !programDetailsLoading && !!programDetails && (
            <ApplicationTitlePanel
              productType={programDetails.ProductType}
              productCode={programDetails.ProductCode}
              friendlyName={programDetails.FriendlyName}
              productProvider={programDetails.ProductProvider}
              hideCatalogLink={routesWithoutCatalog.includes(location.pathname)}
            />
          )}
          <NxuContentLoading mode="dark" />
        </PageContent>
      </RegistrationContext.Provider>
    );
  }

  if (programDetailsError || (programDetailsSuccess && !programDetails)) {
    return (
      <PageContent className="application-page">
        <NxuAlert
          message={
            programDetailsError
              ? `There was an error on loading your selected program: ${programDetailsError.message}.`
              : "We can't find a matching program as the product code or type are missing from the URL"
          }
        />
        <GetInTouch>
          <p>
            <a href={NEXFORD_HOME}>Head over to our catalog to select another course</a>
          </p>
          <p>Any questions? Get in touch and we’ll help.</p>
        </GetInTouch>
      </PageContent>
    );
  }

  if (!token || (!hasActiveRegistration && !hasActiveAdmission)) {
    return (
      <RegistrationContext.Provider value={contextValues}>
        <PageContent className="application-page">
          <h1>Welcome to Nexford ApplyNXU</h1>
          {hasProductParams && (
            <ApplicationTitlePanel
              productType={programDetails!.ProductType}
              productCode={programDetails!.ProductCode}
              friendlyName={programDetails!.FriendlyName}
              productProvider={programDetails!.ProductProvider}
              hideCatalogLink={routesWithoutCatalog.includes(location.pathname)}
            />
          )}
          <CardPanel>
            {!hasProductParams && <ReturningApplicant showAlert={!!token && !hasActiveRegistration} />}
            <EmptyVerificationForm
              onTokenRetrieval={SetAuthenticationInfo}
              pageResetEvent={Reset}
              onLearnerIdRetrieval={(id) => setLearnerId(id)}
              email={email}
              token={token}
            />
            {!hasProductParams && <Catalog />}
          </CardPanel>
          <GetInTouch />
        </PageContent>
      </RegistrationContext.Provider>
    );
  }

  if (admissionRecord && !validAdmissionRecord) {
    return (
      <RegistrationContext.Provider value={contextValues}>
        <PageContent className="application-page">
          <h1 />
          <ApplicationTitlePanel
            productType={programDetails!.ProductType}
            productCode={programDetails!.ProductCode}
            friendlyName={programDetails!.FriendlyName}
            productProvider={programDetails!.ProductProvider}
            hideCatalogLink={routesWithoutCatalog.includes(location.pathname)}
          />
          <ApplicantStatusBlock admissionStatus={admissionRecord.status} token={token} />
        </PageContent>
      </RegistrationContext.Provider>
    );
  }

  return (
    <RegistrationContext.Provider value={contextValues}>
      <PageContent className="application-page">
        <h1 />
        <ApplicationTitlePanel
          productType={programDetails!.ProductType}
          productCode={programDetails!.ProductCode}
          friendlyName={programDetails!.FriendlyName}
          productProvider={programDetails!.ProductProvider}
          hideCatalogLink={routesWithoutCatalog.includes(location.pathname)}
        />
        <ApplicationNavigation
          productType={programDetails!.ProductType}
          productCode={programDetails!.ProductCode}
          registrationData={registrationData}
        />
        {showEmailBlock && (
          <CardPanel>
            <EmptyVerificationForm
              onTokenRetrieval={SetAuthenticationInfo}
              pageResetEvent={Reset}
              email={email}
              token={token}
            />
          </CardPanel>
        )}
        {children}
      </PageContent>
    </RegistrationContext.Provider>
  );
}

export const useRegistrationContext = () => useContext(RegistrationContext);
