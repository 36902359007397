export enum ApplicationStatusOptions {
  APPLYING = "Applying",
  SUBMITTED = "Submitted",
  REJECTED = "Rejected",
  ENROLLING = "Enrolling",
  ENROLLED = "Enrolled",
  NEW = "New",
}

export const MatchStatus = {
  complete: ApplicationStatusOptions.ENROLLED,
  success: ApplicationStatusOptions.ENROLLED,
  checkout: ApplicationStatusOptions.ENROLLING,
};

export type ApplicantStatus =
  | typeof ApplicationStatusOptions.APPLYING
  | typeof ApplicationStatusOptions.SUBMITTED
  | typeof ApplicationStatusOptions.REJECTED
  | typeof ApplicationStatusOptions.ENROLLING
  | typeof ApplicationStatusOptions.ENROLLED
  | typeof ApplicationStatusOptions.NEW;

export interface Group {
  Name: string;
  Courses: Array<string>;
  Required: boolean;
  MinCourses: number;
  MaxCourses: number;
  SpecializatonCode: string;
}

// Response received from API when requesting program product details
export interface Product {
  ProductType: string;
  ProductCode: string;
  ProductProvider: string;
  ProductName: string;
  FriendlyName: string;
  ProductDescription: string;
  ProductTheme: string;
  Specializations: Array<Group>;
  Approved: boolean;
  ApplyMethod: string;
  PublishDate: string;
  RetireDate: string;
  ProductLevel: string;
}

// Response received from course info endpoint
export interface ProductDetails {
  productType: string;
  productCode: string;
  description: string;
  skills: Array<string>;
  degrees: Array<string>;
}

export interface ProductsListItem {
  academicProductName: string;
  friendlyName: string;
  productCode: string;
  productName: string;
  productProvider: string;
  productType: string;
  publishDate?: string;
  retireDate?: string;
}

// Payload sent to API when posting application
export interface ProgramApplicationPayload {
  productCode: string;
  productType: string;
  firstName: string;
  lastName: string;
  yearOfBirth: string;
  email: string;
  nationality: string;
  country: string;
  state: string;
  city: string;
  phoneCountryCode: string;
  phoneNumber: string;
  isNativeEnglishSpeaker: boolean | null;
  isEnglishPrimaryEducationLanguage: boolean | null;
  gender: string;
  howFoundNexford: string;
  highestEducationLevel?: string;
  institutionName?: string;
  degreeName?: string;
  graduationYear?: number;
  isBusinessRelated?: boolean;
}

export interface PreExistingApplicant {
  FirstName: string;
  LastName: string;
  YearOfBirth: string;
  Gender: string;
  PhoneCountryCode: string;
  PhoneNumber: string;
  Nationality: string;
  Country: string;
  State?: string;
  City: string;
}

export interface AdmissionApplicant {
  ApplicationId: string;
  Country: string;
  UserId: string;
  Email: string;
  Status: ApplicantStatus;
  ProductCode: string;
  ProductType: string;
  ApplicantInformation: PreExistingApplicant;
}

export interface EducationFormObject {
  BusinessRelated?: "Yes" | "No" | "Unsure";
  DegreeName?: string;
  EducationLevel?: string;
  EducationLevelOther?: string;
  GraduationYear?: number;
  InstitutionName?: string;
  UnaccreditedInstitutionName?: string;
}
