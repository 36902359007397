import React from "react";
import { IonIcon } from "@ionic/react";
import CardPanel from "components/atom/card-panel";
import { alertCircleOutline } from "ionicons/icons";
import "./applicant-status-block.scss";
import { enrolledMsg, enrollingMsg, pendingMsg, rejectedMsg } from "./applicant-status-messages";
import { ApplicationStatusOptions } from "../../../types/admissions";

export interface ApplicantStatusBlockProps {
  admissionStatus: string;
  token: string;
}

// Content block for applicant to be directed back to the catalogue
const ApplicantStatusBlock = ({ admissionStatus, token }: ApplicantStatusBlockProps) => {
  const infoMessage = () => {
    switch (admissionStatus) {
      case ApplicationStatusOptions.SUBMITTED: {
        return pendingMsg;
      }
      case ApplicationStatusOptions.REJECTED: {
        return rejectedMsg;
      }
      case ApplicationStatusOptions.ENROLLING: {
        return enrollingMsg(token);
      }
      case ApplicationStatusOptions.ENROLLED: {
        return enrolledMsg;
      }
      default: {
        return "";
      }
    }
  };
  return (
    <>
      <CardPanel centre className="applicant-status__info-panel" testId="program-status-info-panel">
        <IonIcon icon={alertCircleOutline} />
        {infoMessage()}
      </CardPanel>
    </>
  );
};

export default ApplicantStatusBlock;
