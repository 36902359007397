import GetInTouch from "components/atom/get-in-touch";

// Collection of messages to be displayed to applicants who already have an existing application or learner profile with Nexford
export const pendingMsg = (
  <>
    <p>You already have an application under review. We'll be in touch soon!</p>
    <GetInTouch type="admission">
      If you have any questions, or would like to change programs, please contact us.{" "}
    </GetInTouch>
  </>
);

export const enrollingMsg = (tokenResponse: string) => (
  <>
    <p>
      You already have a completed application at Nexford University. You can finalise that enrollment by following this{" "}
      <a href={`${process.env.REACT_APP_APPLYV1_URL}/account/verify?key=${tokenResponse}`}>link</a>.
    </p>
    <GetInTouch type="admission">
      If you have any questions, or would like to change programs, please contact us.
    </GetInTouch>
  </>
);

export const enrolledMsg = (
  <>
    <p>
      It looks like you're already enrolled with Nexford University! You can view your program and learner profile on{" "}
      <a href={process.env.REACT_APP_MYNXU_URL}>myNXU</a>.
    </p>
    <GetInTouch type="admission">
      If you have any questions, or would like to change programs, please contact us.
    </GetInTouch>
  </>
);

export const rejectedMsg = (
  <>
    <p>We've noticed that there is a previous application linked to your email address.</p>
    <GetInTouch type="admission">
      Please contact admissions@nexford.org directly if you would like to continue with your application.
    </GetInTouch>
  </>
);
