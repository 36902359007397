import { useMutation, useQuery } from "@tanstack/react-query";

import {
  AgreementAcceptancePayload,
  AgreementAcceptanceResponse,
  AgreementDocumentPayload,
  AgreementDocumentResponse,
  AuthorizePaymentPayload,
  PaymentEstimate,
  PaymentPollingResponse,
  PaymentProviders,
  PaymentStatusResponse,
  PaymentsTypeOptions,
  PaymentTypes,
  ProviderTypes,
  StartPaymentRegistrationPayload,
  StartPaymentRegistrationResponse,
  StripeConfig,
  SubmitPaymentPayload,
  SubmitPaymentResponse,
} from "types/payments";
import { apiGet, apiPost, apiPut } from "./api-client";

export const paymentsErrMsg = {
  fetchProviders:
    "There was an error on getting your payment options. Please refresh and try again or contact support.",
  fetchTuitionFee:
    "There was an error on getting the tuition Fee for your program. Please refresh and try again or contact support.",
  fetchApplicationFee:
    "There was an error on getting the application fee for your program. Please refresh and try again or contact support.",
  fetchPaymentStatus:
    "Uh-oh! There was an error on getting the payment details for your application. Please refresh and try again or contact support.",
  startRegistration:
    "There was an error on initialising your payment option. Please refresh and try again or contact support.",
  skipRegistration:
    "There was an error on proceeding with your application. Please refresh and try again or contact support.",
  completeStripePayment:
    "Uh-oh! There was an error on completing the payment with card. Please contact support to confirm the status of the payment.",
  completeFlutterwavePayment:
    "Uh-oh! There was an error on completing the payment with Flutterwave. Please contact support to confirm the status of the payment.",
  completePaymentPolling:
    "Uh-oh! There was an error on completing the payment. Please contact support to confirm the status of the payment.",
  cancelPaymentPolling:
    "Your payment was cancelled. If this was in error, please try again or contact support for assistance.",
};

const fetchTuitionFee = async (
  productType?: string | null,
  productCode?: string | null,
  startDate?: string | null,
  countryCode?: string | null,
  couponCode?: string | null,
): Promise<PaymentEstimate> =>
  apiGet(
    `/api/billing/tuitionFee/${productType}/${productCode}/${startDate}/${countryCode}/estimate?couponCode=${
      couponCode || ""
    }`,
    paymentsErrMsg.fetchTuitionFee,
  );

const fetchApplicationFee = async (couponCode?: string | null): Promise<PaymentEstimate> =>
  apiGet(`/api/billing/application-fee/estimate?couponCode=${couponCode || ""}`, paymentsErrMsg.fetchApplicationFee);

const useProgramFee = (
  paymentType: PaymentTypes,
  productType?: string | null,
  productCode?: string | null,
  startDate?: string | null,
  countryCode?: string | null,
  couponCode?: string | null,
) => {
  let queryFunc;
  if (paymentType === PaymentsTypeOptions.ApplicationFee) {
    queryFunc = () => fetchApplicationFee(couponCode);
  } else {
    queryFunc = () => fetchTuitionFee(productType, productCode, startDate, countryCode, couponCode);
  }

  return useQuery({
    queryKey: ["product-fee", startDate, couponCode],
    queryFn: queryFunc,
    throwOnError: false,
    enabled: false, // fetch automatically
    refetchOnWindowFocus: false,
    retry: 3,
  });
};

const fetchStripeConfig = async (): Promise<StripeConfig> => apiGet(`/api/admission/config/stripe`);

const useStripeConfig = () =>
  useQuery({
    queryKey: ["stripe-config"],
    queryFn: () => fetchStripeConfig(),
    throwOnError: false,
    // Set enabled to false to block any automated fetches, and only allow this to be triggered manually
    enabled: false,
    retry: 3,
  });

const fetchTuitionFeePaymentProviders = async (
  productType: string,
  productCode: string,
  amount: number,
  country: string,
  startMonth: number,
  paymentFrequency: number,
  couponCode: string | null,
): Promise<PaymentProviders> =>
  apiGet(
    `/api/learner-payments-api/v2/${productType}/${productCode}/tuition-fee/payment-providers?amount=${amount}&payerCountry=${country}&startMonth=${startMonth}&paymentFrequency=${paymentFrequency}&couponCode=${couponCode}`,
    paymentsErrMsg.fetchProviders,
  );

const fetchApplicationFeePaymentProviders = async (
  amount: number,
  country: string,
  startMonth: number,
  couponCode: string | null,
): Promise<PaymentProviders> =>
  apiGet(
    `/api/learner-payments-api/application-fee/payment-providers?amount=${amount}&country=${country}&startMonth=${startMonth}&couponCode=${couponCode}`,
    paymentsErrMsg.fetchProviders,
  );

const usePaymentProviders = (
  paymentType: PaymentTypes,
  productType: string | null,
  productCode: string | null,
  amount: number,
  country: string,
  startMonth: number,
  paymentFrequency: number,
  couponCode: string | null,
) => {
  let queryFunc;
  if (paymentType === PaymentsTypeOptions.ApplicationFee) {
    queryFunc = () => fetchApplicationFeePaymentProviders(amount, country, startMonth, couponCode || "");
  } else {
    queryFunc = () =>
      fetchTuitionFeePaymentProviders(
        productType || "",
        productCode || "",
        amount,
        country,
        startMonth,
        paymentFrequency,
        couponCode || "",
      );
  }

  return useQuery({
    queryKey: ["payment-providers-list", paymentType, productType, productCode, amount, country],
    queryFn: queryFunc,
    throwOnError: false,
    // Payment providers includes a unique callback ID that is refreshed with each request
    // Set enabled to false to block any automated fetches, and only allow this to be triggered manually
    enabled: false,
    retry: 3,
  });
};
const fetchPaymentStatus = async (
  paymentType: string,
  productType: string,
  productCode: string,
): Promise<PaymentStatusResponse> =>
  apiGet(
    `/api/learner-payments-api/learners/self/${productType}/${productCode}/payments/${paymentType}`,
    paymentsErrMsg.fetchPaymentStatus,
  );

const usePaymentStatus = (
  paymentType: PaymentsTypeOptions,
  productType: string | undefined,
  productCode: string | undefined,
) =>
  useQuery({
    queryKey: ["payment-status", !!productType && productType, !!productCode && productCode],
    queryFn: () => fetchPaymentStatus(paymentType, productType || "", productCode || ""),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: !!productType && !!productCode,
    retry: 3,
  });

const fetchPaymentSubscription = async (
  provider: ProviderTypes | null,
  callbackId?: string,
): Promise<PaymentPollingResponse> =>
  apiGet(
    `/api/learner-payments-api/learners/self/payments/${provider}?paymentReference=${callbackId}`,
    paymentsErrMsg.completePaymentPolling,
  );

const usePaymentSubscription = (provider: ProviderTypes | null, callbackId?: string, pollingIsInitiated?: boolean) =>
  useQuery({
    queryKey: ["payment-subscription", provider, callbackId],
    queryFn: () => fetchPaymentSubscription(provider, callbackId),
    enabled: !!provider && !!callbackId && pollingIsInitiated,
    refetchInterval: 3000,
    refetchIntervalInBackground: true,
  });

const useAgreementDocument = () =>
  useMutation({
    mutationFn: (payload: {
      productType: string;
      productCode: string;
      data: AgreementDocumentPayload;
    }): Promise<AgreementDocumentResponse> =>
      apiPost(`/api/agreement/self/${payload.productType}/${payload.productCode}/agreement/unsigned`, payload.data),
  });

const useAgreementAcceptance = () =>
  useMutation({
    mutationFn: (payload: {
      productType: string;
      productCode: string;
      data: AgreementAcceptancePayload;
    }): Promise<AgreementAcceptanceResponse> =>
      apiPost(
        `/api/agreement/self/agreement/${payload.productType}/${payload.productCode}/signed/learner`,
        payload.data,
      ),
  });

const useStartPayment = () =>
  useMutation({
    mutationFn: (payload: StartPaymentRegistrationPayload): Promise<StartPaymentRegistrationResponse> =>
      apiPost("/api/learner-payments-api/learners/self/payments/start-payment", payload),
  });

const useAuthorizePayment = () =>
  useMutation({
    mutationFn: (payload: AuthorizePaymentPayload) =>
      apiPost("/api/learner-payments-api/learners/self/payments/authorize", payload),
  });

const useCompletePaymentProvider = () =>
  useMutation({
    mutationFn: (payload: SubmitPaymentPayload): Promise<SubmitPaymentResponse> =>
      apiPut("/api/learner-payments-api/learners/self/payments/submit-payment", payload),
  });

export {
  usePaymentProviders,
  useProgramFee,
  usePaymentStatus,
  useStripeConfig,
  useAgreementDocument,
  useAgreementAcceptance,
  useStartPayment,
  usePaymentSubscription,
  useAuthorizePayment,
  useCompletePaymentProvider,
};
