import React from "react";

import "./catalog.scss";
import { NxuPrimaryButton } from "@nexford/nexford-ui-component-library";

// Catalog section to be displayed beneath verification when no product is specified in the URL
const Catalog = () => (
  <div className="catalog-section">
    <h2>New to Nexford?</h2>
    <p>Head over to our catalog to select a course.</p>
    <NxuPrimaryButton href={process.env.REACT_APP_APPLYV1_URL}>Go to Catalog</NxuPrimaryButton>
  </div>
);

export default Catalog;
